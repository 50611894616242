import styled from '@emotion/styled';
import {
  Collapse,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
  tableCellClasses,
} from '@mui/material';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { CollapsableTableRow } from 'shared/components';
import { v4 as uuidv4 } from 'uuid';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { formatDecimalNumber } from 'shared/helpers';

export function DefenseTeamMatchStats(props) {
  const { t, i18n } = useTranslation();
  const { stats } = props;
  const [open, setOpen] = useState(false);

  const StyledTableCell = styled(TableCell)(() => ({
    [`&.${tableCellClasses.head}`]: {
      fontFamily: 'Montserrat',
      fontStyle: 'normal',
      fontWeight: '600',
      fontSize: '14px',
      color: '#3E4A59',
      lineHeight: '24px',
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 12,
    },
  }));

  const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover,
    },
    // hide last border
    '&:last-child td, &:last-child th': {
      border: 0,
    },
  }));

  const s = stats;
  const incompletePass = (s?.plays?.pass?.sack || 0)
    + (s?.plays?.pass?.drop || 0)
    + (s?.plays?.pass?.out || 0)
    + (s?.plays?.pass?.tip || 0)
    + (s?.plays?.pass?.intercept || 0)
    + (s?.plays?.pass?.wrong_pass || 0);

  const incompletePassPercentage = (incompletePass / (s?.plays?.pass?.total || 1)) * 100;
  const completePass = (s?.plays?.pass?.total || 0) - incompletePass;

  const completePassPercentage = (completePass / (s?.plays?.pass?.total || 1)) * 100;
  const incompleteRun = (s?.plays?.run?.sack || 0)
    + (s?.plays?.run?.drop || 0);
  const incompleteRunPercentage = (incompleteRun / (s?.plays?.run?.total || 1)) * 100;
  const completeRun = (s?.plays?.run?.total || 0) - incompleteRun;
  const completeRunPercentage = (completeRun / (s?.plays?.run?.total || 1)) * 100;

  const xp1ptGiven = s.extra_points.pt_1.given;
  const xp1ptTotal = s.extra_points.pt_1.total || 1;
  const xp1ptPercentage = (xp1ptGiven / xp1ptTotal) * 100;

  const xp2ptGiven = s.extra_points.pt_2.given;
  const xp2ptTotal = s.extra_points.pt_2.total || 1;
  const xp2ptPercentage = (xp2ptGiven / xp2ptTotal) * 100;

  const calculateTriesPercentage = (total, value) => (
    formatDecimalNumber((value / (total || 1)) * 100, i18n.language)
  );

  function createData(name, value, type = '') {
    return { name, value, type };
  }

  const rows = [
    createData(t('Pontos sofridos'), s?.given_score || 0),
    createData(t('Jardas cedidas'), s?.given_yards || 0),
    createData(`${t('Touchdown passe')}`, s?.plays?.pass?.td || 0),
    createData(`${t('Touchdown corrida')}`, s?.plays?.run?.td || 0),
  ];

  const collapsableRows = {
    extraPoints: [
      createData(`${t('Tentativa')} XP 1pt`, s.extra_points.pt_1.total),
      createData(`${t('Conversão')} XP 1pt`, `${xp1ptGiven} - ${formatDecimalNumber(xp1ptPercentage, i18n.language)}%`),
      createData(`${t('Tentativa')} XP 2pt`, s.extra_points.pt_2.total),
      createData(`${t('Conversão')} XP 2pt`, `${xp2ptGiven} - ${formatDecimalNumber(xp2ptPercentage, i18n.language)}%`),
      // createData(`${t('Tentativa')} XP 2pt - ${t('passe')}`, 0),
      // createData(`${t('Conversão')} XP 2pt - ${t('passe')}`, 0),
      // createData(`${t('Tentativa')} XP 2pt - ${t('Corrida')}`, 0),
      // createData(`${t('Conversão')} XP 2pt - ${t('Corrida')}`, 0),
    ],
    passes: [
      createData(t('Tentados'), s?.plays?.pass?.total || 0),
      createData(t('Completos'), `${completePass} / ${formatDecimalNumber(completePassPercentage, i18n.language)}%` || 0),
      createData(`- ${t('Tackle')}`, s?.plays?.pass?.tackle || 0),
      createData(`- ${t('Saiu de campo')}`, s?.plays?.pass?.out_field || 0),
      createData(`- ${t('Jardas cedidas')}`, s?.plays?.pass?.given_yards || 0),
      createData(t('Incompletos'), `${incompletePass} / ${formatDecimalNumber(incompletePassPercentage, i18n.language)}%` || 0),
      createData(`- ${t('Sack')}`, s?.plays?.pass?.sack || 0),
      createData(`- ${t('Drop')}`, s?.plays?.pass?.drop || 0),
      createData(`- ${t('Bola Fora')}`, s?.plays?.pass?.out || 0),
      createData(`- ${t('Tip')}`, s?.plays?.pass?.tip || 0),
      createData(`- ${t('Interceptações')}`, s?.plays?.pass?.intercept || 0),
      createData(`- ${t('Passe Errado')}`, s?.plays?.pass?.wrong_pass || 0),
    ],
    runs: [
      createData(t('Tentados'), s?.plays?.run?.total || 0),
      createData(t('Completos'), `${completeRun} / ${formatDecimalNumber(completeRunPercentage, i18n.language)}%`),
      createData(`- ${t('Jardas cedidas')}`, s?.plays?.runs?.given_yards || 0),
      createData(t('Incompletos'), `${incompleteRun} / ${formatDecimalNumber(incompleteRunPercentage, i18n.language)}%`),
      createData(`- ${t('Sack')}`, s?.plays?.run?.sack || 0),
      createData(`- ${t('Drop')}`, s?.plays?.run?.drop || 0),
    ],
    firstDowns: [
      createData(t('Total de tentativas'), s?.try?.try_first?.total),
      createData(t('Tentativas convertidas'), s?.try?.try_first?.conversion),
      createData(
        t('Conv. 1ª descida'),
        `${s?.try?.try_first?.try_1?.conversion} - ${calculateTriesPercentage(s?.try?.try_first?.try_1?.total, s?.try?.try_first?.try_1?.conversion)}%`,
      ),
      createData(
        t('Conv. 2ª descida'),
        `${s?.try?.try_first?.try_2?.conversion} - ${calculateTriesPercentage(s?.try?.try_first?.try_2?.total, s?.try?.try_first?.try_2?.conversion)}%`,
      ),
      createData(
        t('Conv. 3ª descida'),
        `${s?.try?.try_first?.try_3?.conversion} - ${calculateTriesPercentage(s?.try?.try_first?.try_3?.total, s?.try?.try_first?.try_3?.conversion)}%`,
      ),
      createData(
        t('Conv. 4ª descida'),
        `${s?.try?.try_first?.try_4?.conversion} - ${calculateTriesPercentage(s?.try?.try_first?.try_4?.total, s?.try?.try_first?.try_4?.conversion)}%`,
      ),
    ],
    touchdowns: [
      createData(t('Total de tentativas'), s?.try?.try_td?.total),
      createData(t('Tentativas convertidas'), s?.try?.try_td?.conversion),
      createData(
        t('Conv. 1ª descida'),
        `${s?.try?.try_td?.try_1?.conversion} - ${calculateTriesPercentage(s?.try?.try_td?.try_1?.total, s?.try?.try_td?.try_1?.conversion)}%`,
      ),
      createData(
        t('Conv. 2ª descida'),
        `${s?.try?.try_td?.try_2?.conversion} - ${calculateTriesPercentage(s?.try?.try_td?.try_2?.total, s?.try?.try_td?.try_2?.conversion)}%`,
      ),
      createData(
        t('Conv. 3ª descida'),
        `${s?.try?.try_td?.try_3?.conversion} - ${calculateTriesPercentage(s?.try?.try_td?.try_3?.total, s?.try?.try_td?.try_3?.conversion)}%`,
      ),
      createData(
        t('Conv. 4ª descida'),
        `${s?.try?.try_td?.try_4?.conversion} - ${calculateTriesPercentage(s?.try?.try_td?.try_4?.total, s?.try?.try_td?.try_4?.conversion)}%`,
      ),
    ],
    faults: [
      createData(t('Cometidas'), s.faults.committed),
      createData(`- ${t('Jardas cedidas')}`, s.faults.yards_given),
      createData(t('Sofridas'), s.faults.received),
      createData(`- ${t('Jardas ganhas')}`, s.faults.yards),
    ],
  };

  const titleMap = {
    extraPoints: t('Pontos Extra'),
    passes: t('Passes'),
    runs: t('Corrida'),
    faults: t('Faltas'),
    firstDowns: t('Tentativa para meio'),
    touchdowns: t('Tentativa touchdown'),
  };

  return (
    <Table sx={{ width: '100%', margin: '20px 0' }} aria-label="customized table">
      <TableHead>
        <TableRow>
          <StyledTableCell component="th" scope="row">
            <Typography
              variant="subtitle1"
              sx={{
                textTransform: 'uppercase',
                fontWeight: 'bold',
              }}
              aria-label="expand row"
              onClick={() => setOpen(!open)}
            >
              {
                open
                  ? <KeyboardArrowUpIcon sx={{ margin: '0 5px', verticalAlign: 'text-bottom' }} />
                  : <KeyboardArrowDownIcon sx={{ margin: '0 5px', verticalAlign: 'text-bottom' }} />
                }
              {t('Time de defesa')}
            </Typography>
          </StyledTableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        <TableRow>
          <TableCell sx={{ padding: 0 }} colSpan={12}>
            <Collapse in={open} timeout="auto" unmountOnExit>
              <Table aria-label="purchases">
                <TableBody>
                  {rows.map((row) => (
                    <StyledTableRow key={uuidv4()}>
                      <StyledTableCell component="th" scope="row">
                        {row.name}
                      </StyledTableCell>
                      <StyledTableCell align="right">{row.value}</StyledTableCell>
                    </StyledTableRow>
                  ))}
                  {
                    Object.entries(collapsableRows).map(([key, collapsableRow]) => (
                      <CollapsableTableRow
                        key={key}
                        header={t(titleMap[key]).toUpperCase()}
                        rows={collapsableRow}
                      />
                    ))
                  }
                </TableBody>
              </Table>
            </Collapse>
          </TableCell>
        </TableRow>
      </TableBody>
    </Table>
  );
}

export default DefenseTeamMatchStats;
