import { Container } from '@mui/material';
import { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { EmptyPage } from 'pages';
import { Loading, MatchCard } from 'shared/components';
import { TeamContext } from 'shared/contexts';
import { Footer, GenericHeader } from 'shared/layouts';
import { useFetchTeamTournamentMatchesQuery } from 'shared/services';

export function StatsListTournamentMatches() {
  const { tournamentId } = useParams();
  const team = useContext(TeamContext);
  const { t } = useTranslation();
  const {
    isLoading,
    isSuccess,
    isError,
    data: matches,
  } = useFetchTeamTournamentMatchesQuery({
    team: team._id, tournament: tournamentId,
  }, { refetchOnMountOrArgChange: true });

  let content;
  if (isLoading) {
    content = <Loading />;
  } else if (isError) {
    content = <EmptyPage title={t('Ainda não há informações')} content={t('Adicione um novo Jogo de Campeonato, Amistoso ou Treino no botão Criar.')} />;
  } else if (isSuccess) {
    content = matches.map((match) => <MatchCard key={`match-card-${match._id}`} match={match} linkTo={`/matches/${match._id}/stats`} actionLabel={t('Ver Estatísticas')} showIcon />);
  }

  return (
    <>
      <div id="stats">
        <GenericHeader title={matches && matches[0]?.tournament} linkBack={`/stats/${team._id}`} />
        <Container component="main" maxWidth="xs" className="home" sx={{ marginTop: '40px', paddingBottom: '110px' }}>
          {content}
        </Container>
        <Footer />
      </div>
      {/* <TournamentLabel label={matches && matches[0]?.tournament} /> */}
    </>
  );
}

export default StatsListTournamentMatches;
