import { Box, Container } from '@mui/material';
import { useContext, useEffect, useRef } from 'react';
import { Outlet, useParams } from 'react-router-dom';
import { TeamContext } from 'shared/contexts';
import { useFetchMatchQuery } from 'shared/services';
import { MatchHeader } from 'shared/layouts';
import {
  CoachIAButton,
  ErrorMessage,
  Loading,
  TimelineStatsMenu,
} from 'shared/components';

export function MatchView() {
  const team = useContext(TeamContext);
  const { matchId } = useParams();
  let match;

  const {
    isLoading,
    isSuccess,
    isError,
    error,
    data,
  } = useFetchMatchQuery({ team: team._id, match: matchId }, { refetchOnMountOrArgChange: true });

  const matchRef = useRef(null);
  useEffect(() => {
    if (isSuccess) {
      matchRef.current = { ...data };
    }
  }, [isSuccess, data]);

  let content;
  let coachIaBtn;
  if (isLoading) {
    content = <Loading />;
  } else if (isError) {
    content = <ErrorMessage messages={error.data.messages} />;
  } else if (isSuccess) {
    match = { ...data };
    match.started = !!match?.time;
    match.ended = !!(match?.time && match?.time?.second_time?.end);
    match.current_time = match.time && match?.time?.first_time?.end ? 2 : 1;
    content = <Outlet context={[match]} />;
    coachIaBtn = <CoachIAButton ended={match?.ended} />;
  }

  return (
    <>
      <MatchHeader />
      <Container
        component="main"
        maxWidth="xs"
        sx={{
          minHeight: 'calc(100vh - 150px)',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: (match?.started) ? 'space-between' : 'space-evenly',
        }}
      >
        <Box sx={{ marginTop: '40px' }}>
          <TimelineStatsMenu color="#0066CC" matchId={matchId} />
        </Box>
        {content}
      </Container>
      {coachIaBtn}
    </>
  );
}

export default MatchView;
