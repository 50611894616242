import React, { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Link,
  Navigate,
  useNavigate,
  useParams,
} from 'react-router-dom';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import ScreenShareIcon from '@mui/icons-material/ScreenShare';
import {
  Box,
  IconButton,
  ListItemIcon,
  Menu,
  MenuItem,
  Typography,
} from '@mui/material';
import {
  BroadcastDialog,
  DeleteMatchDialog,
  ErrorMessage,
  IconLeftArrow,
  Loading, ScoreCard,
} from 'shared/components';
import { TeamContext } from 'shared/contexts';
import {
  useFetchMatchQuery,
} from 'shared/services';

export function MatchHeader() {
  const team = useContext(TeamContext);
  const { t } = useTranslation();
  const { matchId } = useParams();
  const navigate = useNavigate();
  const [state, setState] = useState({
    anchorEl: null,
    openOptions: false,
    openDeleteMatchDialog: false,
    openBroadcastDialog: false,
    redirect: false,
  });
  let redirect = '/dashboard';

  const {
    isLoading,
    isSuccess,
    isError,
    error,
    data,
  } = useFetchMatchQuery({ team: team._id, match: matchId });
  let content;
  if (isLoading) {
    content = <Loading />;
  } else if (isError) {
    content = <ErrorMessage messages={error.data.messages} />;
  } else if (isSuccess) {
    let type = '';
    switch (data.type) {
      case 'tournament':
        type = 'tournaments';
        redirect = `/stats/${team._id}/tournaments/${data.tournament}`;
        break;
      case 'scrimmage':
        type = 'scrimmage';
        redirect = `/stats/${team._id}/scrimmage`;
        break;
      default:
        type = 'practice';
        redirect = `/stats/${team._id}/practice`;
        break;
    }

    const match = { ...data };
    match.started = !!match?.time;
    match.ended = !!(match?.time && match?.time?.second_time?.end);
    match.current_time = match?.time && match?.time?.first_time?.end ? 2 : 1;

    // Options
    const handleOptionsClick = (event) => {
      setState((prevState) => ({ ...prevState, openOptions: true, anchorEl: event.currentTarget }));
    };
    const handleOptionsClose = () => {
      setState((prevState) => ({ ...prevState, openOptions: false }));
    };
    const handleOptionsMenuItemClick = (link) => {
      navigate(link);
      handleOptionsClose();
    };

    const handleClickOpenBroadcastDialog = () => {
      setState((prevState) => ({ ...prevState, openBroadcastDialog: true }));
    };
    const handleCloseBroadcastDialog = () => {
      setState((prevState) => ({ ...prevState, openBroadcastDialog: false }));
    };

    const handleClickOpenDeleteMatchDialog = () => {
      setState((prevState) => ({ ...prevState, openDeleteMatchDialog: true }));
    };
    const handleCloseDeleteMatchDialog = () => {
      setState((prevState) => ({ ...prevState, openDeleteMatchDialog: false }));
    };

    const getLabel = (matchType) => {
      switch (matchType) {
        case 'tournament':
          return t('Jogo de campeonato');
        case 'scrimmage':
          return t('Amistoso');
        default:
          return t('Treino');
      }
    };

    let status;
    switch (true) {
      case match.ended:
        status = t('Finalizado');
        break;
      case match.started:
        status = getLabel(match.type) + t(' em andamento');
        break;
      default:
        status = getLabel(match.type) + t(' não iniciado');
        break;
    }
    content = (
      <Box
        sx={{
          width: '100%',
          height: '150px',
          padding: '20px',
          background: 'linear-gradient(137.13deg, #004D99 31.91%, #0080FF 140.67%)',
          color: '#ffffff',
          textAlign: 'center',
          position: 'relative',
        }}
      >
        <Box
          sx={{
            position: 'absolute',
            left: '20px',
            top: '20px',
          }}
        >
          <Link to={redirect}>
            <IconButton size="small" aria-label="return" sx={{ padding: '0' }}>
              <IconLeftArrow color="#ffffff" />
            </IconButton>
          </Link>
        </Box>
        <header>
          <Typography variant="span" align="center">
            {status}
          </Typography>
        </header>
        <Box
          sx={{
            position: 'absolute',
            right: '20px',
            top: '20px',
          }}
        >
          <IconButton
            aria-label="settings"
            id="long-button"
            aria-controls={state.openOptions ? 'long-menu' : undefined}
            aria-expanded={state.openOptions ? 'true' : undefined}
            aria-haspopup="true"
            onClick={handleOptionsClick}
          >
            <MoreVertIcon sx={{ color: '#ffffff' }} />
          </IconButton>
          <Menu
            id="basic-menu"
            anchorEl={state.anchorEl}
            open={state.openOptions}
            onClose={handleOptionsClose}
            MenuListProps={{
              'aria-labelledby': 'basic-button',
            }}
          >
            <MenuItem onClick={handleClickOpenBroadcastDialog} disableRipple>
              <ListItemIcon>
                <ScreenShareIcon />
              </ListItemIcon>
              {t('Transmitir')}
            </MenuItem>
            <MenuItem onClick={() => handleOptionsMenuItemClick(`/matches/${match?._id}/edit`)} disableRipple>
              <ListItemIcon>
                <EditIcon />
              </ListItemIcon>
              {t('Editar partida')}
            </MenuItem>
            <MenuItem onClick={handleClickOpenDeleteMatchDialog} disableRipple>
              <ListItemIcon>
                <DeleteIcon />
              </ListItemIcon>
              {t('Excluir partida')}
            </MenuItem>
          </Menu>
        </Box>
        <DeleteMatchDialog
          openDeleteMatchDialog={state.openDeleteMatchDialog}
          handleClose={handleCloseDeleteMatchDialog}
          team={team}
          match={match}
          type={type}
          matchTypeLabel={getLabel(match.type)}
        />
        <BroadcastDialog
          openBroadcastDialog={state.openBroadcastDialog}
          handleClose={handleCloseBroadcastDialog}
          team={team}
          match={match}
        />
      </Box>
    );
  }
  return (
    <>
      {state.redirect && <Navigate to={state.redirect} />}
      {content}
      <ScoreCard />
    </>
  );
}

export default MatchHeader;
