import { Paper, TableContainer } from '@mui/material';
import { useContext } from 'react';
import { useOutletContext } from 'react-router-dom';
import { Loading, OffenseTeamMatchStats, DefenseTeamMatchStats } from 'shared/components';
import { TeamContext } from 'shared/contexts';
import { useFetchMatchStatsQuery } from 'shared/services';

export function MatchStatsTable() {
  const team = useContext(TeamContext);
  const [match] = useOutletContext();
  const {
    isLoading,
    isFetching,
    data: stats,
  } = useFetchMatchStatsQuery({ team: team._id, match: match._id });

  if (isLoading || isFetching) {
    return <Loading />;
  }

  const content = (
    <>
      <OffenseTeamMatchStats stats={stats.offense} />
      <DefenseTeamMatchStats stats={stats.defense} />
    </>
  );

  return (
    <TableContainer component={Paper} sx={{ flex: 1 }}>
      {content}
    </TableContainer>
  );
}

export default MatchStatsTable;
